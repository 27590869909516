import "./css/NotFound.scss"
import longmon from "./img/warning.svg"
import { Helmet } from "react-helmet"
export default function NotFound(props) {
    return (
        <div className="page-container notfound-container">
            <Helmet>
                <title>LongLong Finance - 404 not found</title>
                <meta name="description" content="404 page not found error" />
            </Helmet>
            <img src={longmon} alt="" />
            <p>page not found</p>

        </div>
    )
}