import { Component, isValidElement } from "react"
import vaultList from "../utils/vaultList"
import "./css/Dashboard.scss"
import Loading from "./Loading"
import ConnectWarningCard from "./ConnectWarningCard"
import { getTokenPrice } from "../utils/tokenPrice"
import { fundInVault, getCapacity, getValue, getVehicleList } from "../utils/vaultInterface"
import { useIVContract } from "../utils/useContract"
import { fundInIV } from "../utils/vehicleInterface"
import BigNumber from "bignumber.js"

export default class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.props.setPage("Dashboard")

        this.state = {
            vaultList: [],
            rawIVlist: [],
            loading: false,
            displayInfo: false
        }
    }

    componentDidMount = async () => {
        let _vl = new vaultList("long")
        _vl = _vl.getVaultList()
        let _vh = new vaultList("hodl")
        _vh = _vh.getVaultList()
        var vaultlist = await Promise.all([_vl, _vh]).then((res) => {
            return [...res[0], ...res[1]]
        })
        await this.setState({
            vaultList: vaultlist,
        })
        //var vaultlist = [...vl, ...vf]
        //console.log(vaultlist)
    }

    componentDidUpdate = async (prevprops) => {
        if (this.props.accountState.chainId !== prevprops.accountState.chainId) {
            this.setState({ "displayInfo": false })
        }

    }

    showInfo = async (vault) => {
        this.setLoading(true)
        this.setState({ "displayInfo": false })
        this.setState({
            "vault": vault
        })
        await this.getVaultInfo(vault)
        //await this.getIVInfo()
        this.setLoading(false)
        this.setState({ "displayInfo": true })
    }

    toReadableNumber = (string, digits) => {
        return Number(string).toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits })
    }

    getVaultInfo = async (vault) => {
        let _baseAmount = vault.vaultContract.methods.totalSupply().call()
        let _fundInVault = fundInVault(vault.address, vault.vaultContract)
        let _baseAssetValue = getTokenPrice(vault.baseToken.address)
        let _vaultValue = getValue(vault)
        let _setDepositCap = vault.vaultContract.methods.depositCap().call()
        let _currentDepositCap = vault.vaultContract.methods.totalSupply().call()
        let _capacity = getCapacity(vault)
        let _numOfIVs = vault.vaultContract.methods.investmentVehiclesLength().call()
        let _listOfIVs = getVehicleList(vault.vaultContract)
        var _selfCompoundingLongAsset = vault.vaultContract.methods.selfCompoundingLongAsset.call().call()
        await Promise.all([_baseAmount, _baseAssetValue, _vaultValue, _setDepositCap, _currentDepositCap, _capacity, _numOfIVs, _listOfIVs, _selfCompoundingLongAsset, _fundInVault])
            .then((res) => {
                let IVlist = ""
                for (let i in res[7]) {
                    if (i === 0) { IVlist = `${res[7][i]}` }
                    else IVlist = `${IVlist}\n${res[7][i]}`
                }
                this.setState({
                    "baseAmount": this.toReadableNumber(vault.baseToken.strNumToDisplay(res[0]), vault.baseToken.decimal) + " " + vault.baseToken.symbol.toUpperCase(),
                    "baseAssetPrice": "$" + res[1],
                    "vaultValue": "$" + res[2],
                    "setDepositCap": this.toReadableNumber(vault.baseToken.strNumToDisplay(res[3]), vault.baseToken.decimal) + " " + vault.baseToken.symbol.toUpperCase(),
                    "currentDepositCap": this.toReadableNumber(vault.baseToken.strNumToDisplay(res[4]), vault.baseToken.decimal) + " " + vault.baseToken.symbol.toUpperCase(),
                    "capacity": res[5][1]+"/"+res[5][0]+" ("+res[5][2]+"%)",
                    "numOfIVs": res[6],
                    "rawIVlist": res[7],
                    "listOfIVs": IVlist,
                    "selfCompoundingLongAsset": res[8],
                    "addCollectAndLongAddress": res[7][0],
                    "CollectAndLongAddressList": [],
                    "minimumLongProfit": 0,
                    "collectAndLongResult": "",
                    "investAllResult": "",
                    "fundInVault": this.toReadableNumber(vault.baseToken.strNumToDisplay(res[9]), vault.baseToken.decimal) + " " + vault.baseToken.symbol.toUpperCase(),
                })
            })
    }

    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    collectAndLong = () => {
        let account = this.props.accountState.account[0]
        let address = this.state.CollectAndLongAddressList
        let minimumProfit = this.state.vault.baseToken.displayToFunctionInput(this.state.minimumLongProfit)
        this.state.vault.vaultContract.methods.collectAndLong(address, minimumProfit).send({ from: account })
            .on('sent', (payload) => {
                this.setState({
                    "collectAndLongResult": "pending"
                })
            })
            .on('confirmation', (ConfirmationNum, receipt) => {
                if (ConfirmationNum > 0)
                    return

                this.setState({
                    "collectAndLongResult": "pending"
                })

                this.showInfo()

            })
            .on('error', (error, receipt) => {
                this.setState({
                    "collectAndLongResult": error.message
                })
            })

    }

    investAll = async () => {
        let account = this.props.accountState.account[0]
        await this.state.vault.vaultContract.methods.investAll()
            .send({ from: account })
            .on('sent', (payload) => {
                this.setState({
                    "investAllResult": "pending"
                })
            })
            .on('confirmation', (ConfirmationNum, receipt) => {
                if (ConfirmationNum > 0)
                    return

                this.setState({
                    "investAllResult": "pending"
                })

                this.showInfo()

            })
            .on('error', (error, receipt) => {
                this.setState({
                    "investAllResult": error.message
                })
            })

    }

    setLoading = (props) => {
        this.setState({
            loading: props
        })
    }

    addAddress = () => {
        let list = this.state.CollectAndLongAddressList
        list.push(this.state.addCollectAndLongAddress)
        this.setState({
            CollectAndLongAddressList: list
        })
    }

    render() {
        return (
            <>
                {(!this.props.accountState.metaMaskConnected || !this.props.accountState.metaMaskInstalled) &&
                    <ConnectWarningCard updateAppState={this.props.updateAppState}
                        metaMaskInstalled={this.props.accountState.metaMaskInstalled}
                        metaMaskConnected={this.props.accountState.metaMaskConnected} />
                }
                <div className="page-container dashboard-container">
                    <div className="vault-btn-container">
                        {
                            this.state.vaultList.map((element, idx) => {
                                if (element.chainId === this.props.accountState.chainId)
                                    return <DashboardBtn vault={element} onClick={() => { this.showInfo(element) }} />
                            })
                        }
                    </div>
                    {
                        (this.state.loading) &&
                        <Loading text="" />
                    }
                    {
                        (this.state.displayInfo) &&
                        <div className="info-container">
                            <div className="h1">{this.state.vault.name + " Info"}</div>
                            <div className="h2">Vault Info</div>
                            <div className="vault-info-container">
                                <Info title="vault total fund" content={this.state.baseAmount} />
                                <Info title="fund in vault" content={this.state.fundInVault} />
                                <Info title="base asset price" content={this.state.baseAssetPrice} />
                                <Info title="vault value(rounded)" content={this.state.vaultValue} />
                                <Info title="current deposit cap" content={this.state.setDepositCap} />
                                <Info title="capacity (%)" addClass=" address" content={this.state.capacity} />
                                <Info title="#IVs" content={this.state.numOfIVs} />
                                <Info title="IV list" addClass=" address" content={this.state.listOfIVs} />
                                <Info title="selfCompoundingLongAsset" addClass=" address" content={this.state.selfCompoundingLongAsset} />
                            </div>
                            <div className="vault-function-container">
                                <>
                                    <div className="function-title">function</div>
                                    <div className="vault-function">
                                        <div className="vault-function-name">collectAndLong</div>
                                    </div>
                                    <div className="vault-function">
                                        <input type="text" className="input-address" placeholder="address" value={this.state.addCollectAndLongAddress} name="addCollectAndLongAddress" onChange={this.inputChange} />
                                        <input type="button" className="btn-1" value="add address" onClick={this.addAddress} />
                                    </div>
                                    <div className="function-result addresslist">
                                        <div>address list : </div>
                                        {
                                            this.state.CollectAndLongAddressList.map((element, idx) => {
                                                return (<div>
                                                    {element}
                                                </div>)
                                            })}
                                    </div>
                                    <div className="vault-function">
                                        <input type="text" value={this.state.minimumLongProfit} placeholder="minimumLongProfit" name="minimumLongProfit" onChange={this.inputChange} />
                                        <input type="button" value="send" className="btn-2" onClick={this.collectAndLong} />
                                    </div>
                                    <div className="function-result">{this.state.collectAndLongResult}</div>
                                </>
                                <>
                                    <div className="function-title">function</div>
                                    <div className="vault-function">
                                        <div className="vault-function-name">investAll</div>
                                        <input type="button" value="send" className="btn-2" onClick={this.investAll} />
                                    </div>
                                    <div className="function-result">{this.state.investAllResult}</div>
                                </>
                            </div>
                            {
                                this.state.rawIVlist.map((element, idx) => {
                                    return <IVitem IV={element} baseToken={this.state.vault.baseToken} accountState={this.props.accountState} />
                                })
                            }

                        </div>
                    }



                </div>
            </>
        )
    }
}

export function DashboardBtn(props) {
    return (
        <div className="vault-btn" onClick={props.onClick}>
            <div className="vault-title">{props.vault.name}</div>
            <div className="asset">{props.vault.baseToken.symbol + " -> " + props.vault.longToken.symbol}</div>
            <div className="address">{props.vault.address.substr(0, 6) + "..." + props.vault.address.substr(-4)}</div>
        </div>
    )
}

export function Info(props) {

    return (
        <div className={props.addClass ? "info" + props.addClass : "info"}>
            <div className="info-title">{props.title}</div>
            <div className="info-content">{props.content}</div>
        </div>
    )
}



export class IVitem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "investmentVehicle": props.IV,
            "loading": false,
            "baseToken": props.baseToken,
            "displayInfo": false,
            "baseAssetBalanceOf": "",
            "baseAssetBalanceOfResult": "",
            "investAllResult": "",
            "collectProfitAndDistributeResult": "",
            "collectProfitAndDistributeMinimumProfit": 0
        }
    }

    componentDidMount = async () => {
        this.setLoading(true)
        this.setState({ "displayInfo": false })
        this.IVContract = new useIVContract(this.props.IV)
        this.setState({
            investmentVehicle: this.IVContract,
        })
        await this.getIVInfo(this.props.IV, this.IVContract)
        this.setLoading(false)
        this.setState({ "displayInfo": true })
    }

    toReadableNumber = (string, digits) => {
        return Number(string).toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits })
    }

    getIVInfo = async (ivAddress, ivContract) => {
        //fund in IV (balanceof(IV))
        let _fundInIV = fundInIV(ivAddress, ivContract)
        let _invested = ivContract.methods.invested().call()
        ivContract.methods.profitsPending().call()
            .then((res) => {
                this.setState({
                    "profitsPending": this.toReadableNumber(this.state.baseToken.strNumToDisplay(res), this.state.baseToken.decimal) + " " + this.state.baseToken.symbol.toUpperCase(),
                })
                
            })
            .catch((error) => {
                this.setState({
                    "profitsPending": "reverted",
                })
            })
            
            
        Promise.all([_fundInIV, _invested]).then((res) => {
            let iVTotalFund = BigNumber(res[0]).plus(res[1])
            let investedRatio = (BigNumber(res[1]).div(iVTotalFund)).times(100).toFixed(2).toString()
            //console.log(this.toReadableNumber(this.state.baseToken.strNumToDisplay(res[2]), this.state.baseToken.decimal) + " " + this.state.baseToken.symbol.toUpperCase())
            this.setState({
                "fundInIV": this.toReadableNumber(this.state.baseToken.strNumToDisplay(res[0]), this.state.baseToken.decimal) + " " + this.state.baseToken.symbol.toUpperCase(),
                "iVTotalFund": this.toReadableNumber(this.state.baseToken.strNumToDisplay(iVTotalFund), this.state.baseToken.decimal) + " " + this.state.baseToken.symbol.toUpperCase(),
                "invested": this.toReadableNumber(this.state.baseToken.strNumToDisplay(res[1]), this.state.baseToken.decimal) + " " + this.state.baseToken.symbol.toUpperCase(),
                //"profitsPending": 0,//this.toReadableNumber(this.state.baseToken.strNumToDisplay(res[2]), this.state.baseToken.decimal) + " " + this.state.baseToken.symbol.toUpperCase(),
                "investedRatio": investedRatio
            })
        })

        //IV invested ratio (invested/balanceof(__))
        //profitsPending
        //baseAssetBalanceOf (with input)

    }

    updateData = async () => {
        this.setLoading(true)
        this.setState({ "displayInfo": false })
        this.IVContract = new useIVContract(this.props.IV)
        this.setState({
            investmentVehicle: this.IVContract,
        })
        await this.getIVInfo(this.props.IV, this.IVContract)
        this.setLoading(false)
        this.setState({ "displayInfo": true })
    }

    inputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setLoading = (props) => {
        this.setState({
            loading: props
        })
    }

    baseAssetBalanceOf = async () => {
        let account = this.props.accountState.account[0]
        let address = this.state.baseAssetBalanceOf
        await this.IVContract.methods.baseAssetBalanceOf(address).call()
            .then((res) => {
                let displayAmount = this.toReadableNumber(this.state.baseToken.strNumToDisplay(res))
                this.setState({ "baseAssetBalanceOfResult": displayAmount })
            })
            .catch((error) => {
                this.setState({ "baseAssetBalanceOfResult": error.message })
            }
            )
    }


    investAll = async () => {
        let account = this.props.accountState.account[0]
        await this.IVContract.methods.investAll()
            .send({ from: account })
            .on('sent', (payload) => {
                this.setState({
                    "investAllResult": "pending"
                })
            })
            .on('confirmation', (ConfirmationNum, receipt) => {
                if (ConfirmationNum > 0)
                    return

                this.setState({
                    "investAllResult": "success"
                })

            })
            .on('error', (error, receipt) => {
                this.setState({
                    "investAllResult": error.message
                })
            })

    }

    collectProfitAndDistribute = async () => {
        let amount = "0x" + this.state.baseToken.displayToBigNum(this.state.collectProfitAndDistributeMinimumProfit).toString(16)
        let account = this.props.accountState.account[0]
        await this.IVContract.methods.collectProfitAndDistribute(amount)
            .send({ from: account })
            .on('sent', (payload) => {
                this.setState({
                    "collectProfitAndDistributeResult": "pending"
                })
            })
            .on('confirmation', (ConfirmationNum, receipt) => {
                if (ConfirmationNum > 0)
                    return

                this.setState({
                    "collectProfitAndDistributeResult": "success"
                })

            })
            .on('error', (error, receipt) => {
                this.setState({
                    "collectProfitAndDistributeResult": error.message
                })
            })

    }

    render() {
        return (
            <>
                {
                    (this.state.loading) &&
                    <Loading text="" />
                }
                {
                    (this.state.displayInfo) &&
                    <div className="info-container iv-container">
                        <div className="h2">{"IV " + this.props.IV}</div>
                        <div className="vault-info-container">
                            <Info title="fund in IV" content={this.state.fundInIV} />
                            <Info title="invested" content={this.state.invested} />
                            <Info title="IV total fund" content={this.state.iVTotalFund} />
                            <Info title="profitsPending" content={this.state.profitsPending} />
                            <Info title="investedRatio" content={this.state.investedRatio} />
                        </div>
                        <div className="vault-function-container">
                            <>
                                <div className="function-title">function</div>
                                <div className="vault-function">
                                    <div className="vault-function-name">baseAssetBalanceOf</div>
                                    <input type="text" className="input-address" value={this.state.baseAssetBalanceOf} placeholder="address" name="baseAssetBalanceOf" onChange={this.inputChange} />
                                    <input type="button" value="send" className="btn-2" onClick={this.baseAssetBalanceOf} />
                                </div>
                                <div className="function-result">{this.state.baseAssetBalanceOfResult}</div>
                            </>
                            <>
                                <div className="function-title">function</div>
                                <div className="vault-function">
                                    <div className="vault-function-name">investAll</div>
                                    <input type="button" value="send" className="btn-2" onClick={this.investAll} />
                                </div>
                                <div className="function-result">{this.state.investAllResult}</div>
                            </>
                            <>
                                <div className="function-title">function</div>
                                <div className="vault-function">
                                    <div className="vault-function-name">collectProfitAndDistribute</div>
                                    <input type="text" value={this.state.collectProfitAndDistributeMinimumProfit} name="collectProfitAndDistributeMinimumProfit" placeholder="minimumProfit" onChange={this.inputChange} />
                                    <input type="button" value="send" className="btn-2" onClick={this.collectProfitAndDistribute} />
                                </div>
                                <div className="function-result">{this.state.collectProfitAndDistributeResult}</div>
                            </>
                        </div>

                    </div>
                }
            </>
        )
    }

}