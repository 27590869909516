import { useEffect, useState, useRef } from "react"
import "./css/ChangeChainCard.scss"
export default function ChangeChainCard(props) {
    const [text, setText] = useState("")
    const [func, setFunction] = useState()
    function switchToPolygon() {
        const Polygon_MAINNET_PARAMS = {
            chainId: '0x89',
            chainName: 'Matic Mainnet',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
            blockExplorerUrls: ['https://polygonscan.com/']
        }

        window.ethereum
            .request({
                method: 'wallet_addEthereumChain',
                params: [Polygon_MAINNET_PARAMS]
            })
        props.toggleCardDisplay()
    }

    function switchToEthereum() {
        const ETHEREUM_MAINNET_PARAMS = {
            chainId: '0x1',
        }

        window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [ETHEREUM_MAINNET_PARAMS]
        })
            .catch((error: any) => {
                console.log(error)
            })
        props.toggleCardDisplay()
    }

    useEffect(() => {
        if (props.chainID === "0x1") {
            setText("switch to Polygon")
            setFunction(() => () => switchToPolygon())
        }
        else if (props.chainID === "0x89") {
            setText("switch to Ethereum")
            setFunction(() => () => switchToEthereum() )
        }
     },[props.chainID])
    

    
    return (
        <div className="card">
            <div className="changechaincard-container" ref={props.reference}>
                <div className="changechaincard-text" ref={props.reference}>
                    Chain Options:
                </div>
                <input className="primary-btn" type="button" value={text} onClick={func} ref={props.reference}/>
            </div>
        </div>
    )
    
    
}