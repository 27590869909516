import { useEffect, useState } from "react"
import { getVaultApy, getValue } from "../utils/vaultInterface"
import "./css/Vault.scss"
import longtkn from "./img/BASE.png"
import "./css/Tooltip.scss"
import Tooltip from "./Tooltip"

const VaultItem = (props) => {
    const vaultInfo = props.vaultInfo
    const addClass = (props.addClass) ? props.addClass : ""
    const baseSymbol = (props.isTitle) ? vaultInfo.baseToken : vaultInfo.baseToken.symbol.toUpperCase()
    const longSymbol = (props.isTitle) ? vaultInfo.longToken : vaultInfo.longToken.symbol.toUpperCase()
    const baseImg = (props.isTitle) ? vaultInfo.baseToken : require("./img/" + baseSymbol + ".png").default
    const longImg = (props.isTitle) ? vaultInfo.longToken : require("./img/" + longSymbol + ".png").default
    const [apy, setApy] = useState(vaultInfo.apy)
    const [scapy, setSCApy] = useState(vaultInfo.apy)
    const [value, setValue] = useState(vaultInfo.value)
    const tooltipApyText = "Base -> Long Apy : " + apy
    const tooltipSCApyText = (scapy==="-%")? "": "Long Compounding Apy : " + scapy
    const apyDisplay = (scapy === "-%") ? apy : apy + "++"
    useEffect(async () => {
        if (props.vaultInfo.id !== -1) {
            var _apy = getVaultApy(props.vaultInfo)
            var _value = getValue(props.vaultInfo)
            Promise.all([_apy, _value]).then((res) => {
                setApy(res[0][0])
                setSCApy(res[0][1])
                setValue(Number(res[1]).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))
                props.addTotalValue(res[1])
            })
        }
    }, [props.vaultInfo])

    return (

        <div>
            <div data-vault={vaultInfo.id} id="vault-item-container" onClick={props.onClick} className={addClass}>
                <div id="vault-name" data-vault={vaultInfo.id}>{vaultInfo.name}</div>
                <div id="tkn-container" data-vault={vaultInfo.id}>
                    {
                        (!props.isTitle) &&
                        <img id="tkn-img" data-vault={vaultInfo.id} src={baseImg} alt="" />
                    }
                    <div id="base-token" data-vault={vaultInfo.id}>
                        {
                            (!props.isTitle && !vaultInfo.baseToken.isLPtoken) &&
                            <>
                                {baseSymbol}
                            </>
                        }
                        {
                            (!props.isTitle && vaultInfo.baseToken.isLPtoken && vaultInfo.baseToken.chainId!==1) &&
                            <>
                                {baseSymbol}
                            </>
                        }
                        {
                            (!props.isTitle && vaultInfo.baseToken.isLPtoken && vaultInfo.baseToken.chainId===1) &&
                            <div data-vault={vaultInfo.id} className="tooltip-container">
                                {baseSymbol}
                                <span className="tooltip" data-vault={vaultInfo.id}>
                                    <p data-vault={vaultInfo.id}>
                                        To obtain {vaultInfo.baseToken.symbol.toUpperCase()}, deposit your tokens at <a href={vaultInfo.baseTokenLink} data-vault={vaultInfo.id} target="_blank">curve.fi</a>
                                    </p>
                                </span>
                            </div>
                        }
                        
                        {
                            (props.isTitle) &&
                            <>
                            { baseSymbol }
                            </>
                        }
                    </div>
                </div>
                <div id="tkn-container" data-vault={vaultInfo.id}>
                    {
                        (!props.isTitle) &&
                        <img id="tkn-img" data-vault={vaultInfo.id} src={longImg} alt="" />
                    }
                    <div id="long-token" data-vault={vaultInfo.id}>
                    {
                            (!props.isTitle && vaultInfo.longToken.isLPtoken && vaultInfo.longToken.chainId===1) &&
                            <div data-vault={vaultInfo.id} className="tooltip-container">
                                {longSymbol}
                                <span className="tooltip" data-vault={vaultInfo.id}>
                                    <p data-vault={vaultInfo.id}>
                                        This vault is longing {vaultInfo.longToken.symbol.toUpperCase()}, check <a href={vaultInfo.longTokenLink} data-vault={vaultInfo.id} target="_blank">curve.fi</a> for details.
                                    </p>
                                </span>
                            </div>
                        }
                        {
                            (!props.isTitle && !vaultInfo.longToken.isLPtoken && vaultInfo.longToken.symbol!=="PUNK") &&
                            <>
                                {longSymbol}
                            </>
                        }
                        {
                            (!props.isTitle && vaultInfo.longToken.symbol==="PUNK") &&
                            <div data-vault={vaultInfo.id} className="tooltip-container">
                                {longSymbol}
                                <span className="tooltip" data-vault={vaultInfo.id}>
                                    <p data-vault={vaultInfo.id}>
                                        This vault is longing fractional CryptoPunks powered by <a href="https://nftx.org/" data-vault={vaultInfo.id} target="_blank">NFTX</a>.
                                        Check out the <a href="https://app.nftx.org/redeem/0x269616d549d7e8eaa82dfb17028d0b212d11232a/" data-vault={vaultInfo.id} target="_blank">NFTX punk vault</a> for more details.
                                    </p>
                                </span>
                            </div>
                        }
                        {
                            (props.isTitle) &&
                            <>
                            { longSymbol }
                            </>
                        }
                    </div>
                </div>
                {
                    (!props.isTitle) &&
                    <div id="apy" data-vault={vaultInfo.id}>
                        <div data-vault={vaultInfo.id} className="tooltip-container">
                            {apyDisplay}
                            <span className="tooltip" data-vault={vaultInfo.id}>
                                {tooltipApyText}
                                <br />
                                {tooltipSCApyText}
                            </span>
                        </div>
                    </div>
                }
                {
                    (props.isTitle) &&
                    <div id="apy" data-vault={vaultInfo.id}>
                        {apy}
                    </div>
                }

                <div id="value" data-vault={vaultInfo.id}>{"$ " + value}</div>
            </div>
        </div>
    )
}
export default VaultItem
