import { Component } from "react"
import "./css/HomePage.scss"
import longmon from "./img/longmon.svg"
import cloud1 from "./img/cloud1.svg"
import cloud2 from "./img/cloud2.svg"
import { Link } from "react-router-dom"

export default class HomePage extends Component{
    constructor(props){
        super(props)
        this.props.setPage("LongLong Finance")
        this.state = {
            mouseX: 0,
            mouseY: 0
        }
    }

    handleMouseMove = (e) => {
        this.setState({
            mouseX: (e.clientX-e.clientY)*0.1,
            mouseY: e.clientY*0.1
        })
    }
    
    render(){
        return(
            <>
                <div className="homepage-container page-container" onMouseMove={this.handleMouseMove}>
                    <h1>LongLong Finance</h1>
                    <p className="intro-text">Diversifying your principal and yield into different assets. <br/>Long Crypto, Long Family.</p>
                    <div className="btn-2">
                        <Link to="/long">
                            <div className="text">go to Vaults</div>
                        </Link>
                    </div>
                    <div id="longmon-container">
                        <img id="longmon" src={longmon} alt="LongLong Finance Monster" />
                    </div>
                    <img id="cloud1" style={{
                        transform: `translate(${this.state.mouseX}px, 0px)`,
                        transition: `transform .5s`
                    }}
                        src={cloud1} alt="" />
                    <img id="cloud2" style={{
                        transform: `translate(${-1*this.state.mouseX}px, 0px)`,
                        transition: `transform .5s`
                    }} src={cloud2} alt="" />
                </div>
            </>
        )
    }
}
