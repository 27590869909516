import warning from "../components/img/warning.svg"
import "./css/Error.scss"
export default function Error(props) {
    var errorMessage = "Oops, something happened"
    if (props.errorcode === 4001) {
        errorMessage = "The request was rejected."
    }
    if (props.errorcode === -32602) {
        errorMessage = "something happened, please report to us, we'll fix it ASAP"
    }
    if (props.errorcode === -32603) {
        errorMessage = "some internal error happened to metamask, please try later"
    }

    return (
        <div id="pending-container">
            <img src={warning} alt="warning" />
            <div id="pending-text">
                {errorMessage}
            </div>
            <input className="btn-1" type="button" value="OK" onClick={() => { props.setPending({pending:false, error:false})}}/>
        </div>
    )
}