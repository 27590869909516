// get single vehicle invested amount
//fund in IV (balanceof(IV))
//IV invested ratio (invested/balanceof(__))
//profitsPending
//baseAssetBalanceOf (with input)
import { useTokenContract } from "./useContract"

export async function fundInIV(ivAddress, ivContract) {
    let baseAsset = await ivContract.methods.baseAsset.call().call()
    let tknContract = new useTokenContract(baseAsset)
    let balance = await tknContract.methods.balanceOf(ivAddress).call()
    return balance
}
