import { useEffect, useMemo } from "react";
import React from "react";
const AlchemyBadge = React.memo( function AlchemyBadge(props) {
    let BADGE_ID = 'd8cd71c062b9e91c';
    let ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`;
    const ALCHEMY_ANALYTICS_URL = `https://analytics.alchemyapi.io/analytics`

    useEffect (() => {
        logBadgeView();
    })

    function logBadgeClick() {
        fetch(`${ALCHEMY_ANALYTICS_URL}/badge-click`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                badge_id: BADGE_ID,
            }),
        })
    }

    function logBadgeView() {
        fetch(`${ALCHEMY_ANALYTICS_URL}/badge-view`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                badge_id: BADGE_ID,
            }),
        });
    }

    return (
        <a href={ `https://alchemyapi.io/?r=badge:${BADGE_ID}`} target="_blank">
            <img id="badge-button" onClick={logBadgeClick} src="https://static.alchemyapi.io/images/marketing/badgeLight.png" alt="Alchemy Supercharged" />
        </a>
    )
})

export default AlchemyBadge