import BigNumber from "bignumber.js"
import { getTokenPrice } from "./tokenPrice"
import { useIVContract, useMaticSwapCenterContract, useSCContract, useSwapCenterContract, useTokenContract } from "./useContract"
import mainnetAddress from "../address/mainnet.json"
import tokenList from "../address/token.json"
import baseCurrency from "./baseCurrency"

export async function getDepositData(vault, account) {
    // get balance of account from baseToken
    const _availableBase = vault.baseToken.balanceOf(account[0])
    // get deposited Base from vault contract
    const _depositedBase = vault.vaultContract.methods.balanceOf(account[0]).call()
    /* TODO for future vault construct, should build a vault token*/

    // get claimableLong from vault contract
    // get claimableRewards from vault(?) or Rewards contract 

    const ans = await Promise.all([_availableBase,_depositedBase])
    // parse into display view
    // todo add the parsing part into .then function after Promise.all
    ans[0] = await vault.baseToken.strNumToDisplay(ans[0])
    ans[1] = await vault.baseToken.strNumToDisplay(ans[1])
    return ({
        "availableBase": ans[0],
        "depositedBase": ans[1],
    })
}

// Check Capacity
export async function checkCapacity(vault, amount, setCapacityWarning) {
    var _totalSupply = vault.vaultContract.methods.totalSupply().call()
    var _depositCap = vault.vaultContract.methods.depositCap().call()
    var full = await Promise.all([_totalSupply, _depositCap]).then((res) => {
        var totalSupply = BigNumber(res[0])
        var depositCap = BigNumber(res[1])
        if (totalSupply.plus(amount).comparedTo(depositCap) > 0) {
            setCapacityWarning(true)
            return true
        }
        setCapacityWarning(false)
        return false   
    })
    return full
}

// Deposit to Vault
export async function depositBase(vault, account, setPending, amount, setCapacityWarning, infiniteApprove) {
    var full = await checkCapacity(vault, vault.baseToken.displayToBigNum(amount), setCapacityWarning)
    if (full)
        return
    await vault.baseToken.checkAllowance(account, vault.address, vault.baseToken.displayToBigNum(amount), setPending, infiniteApprove)
    await vault.vaultContract.methods.deposit("0x"+vault.baseToken.displayToBigNum(amount).toString(16)).send({from:account[0]})
    .on('sent',(payload)=>{
        setPending({"pending":true})
    })
    .on('confirmation', (ConfirmationNum, receipt)=>{
        if (ConfirmationNum === 0) {
            setPending({ "success": true })
            window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false })}), 1500)
        }    
    })
    .on('error', (error,receipt)=>{
        setPending({"pending":false, "error":true, "errorcode":error.code})
    })
}

// Deposit with other token
export async function depositToken(vault, tokenAddress, account, setPending, amount, setCapacityWarning, slipage, infiniteApprove) {
    var _tokenPrice = getTokenPrice(tokenAddress)
    var _baseAssetPrice = getTokenPrice(vault.baseToken.address)
    var price = await Promise.all([_tokenPrice, _baseAssetPrice])
    var token = new baseCurrency(tokenList[tokenAddress])
    amount = token.displayToBigNum(amount)
    var intendedDeposit = amount.shiftedBy(-1 * token.decimal).times(price[0]).div(price[1]).toString()
    var full = await checkCapacity(vault, vault.baseToken.displayToBigNum(intendedDeposit), setCapacityWarning)
    if (full)
        return
    slipage = BigNumber(slipage).div(100)
    //var minimumDeposit = amount.minus(amount.times(slipage)).shiftedBy(-1 * token.decimal).times(price[0]).div(price[1]).shiftedBy(1 * vault.baseToken.decimal).toFixed(0, 1)
    var minimumDeposit = amount.minus(amount.times(slipage)).shiftedBy(-1 * token.decimal).times(price[0]).div(price[1]).shiftedBy(1 * vault.baseToken.decimal).toFixed(0,1)
    minimumDeposit = BigNumber(minimumDeposit)
    if (vault.chainId === "0x1") {
        var swapcenterContract = new useSwapCenterContract()
        await token.checkAllowance(account, mainnetAddress.core.SwapCenter, amount, setPending, infiniteApprove)
    }
    else if (vault.chainId === "0x89") {
        var swapcenterContract = new useMaticSwapCenterContract()
        await token.checkAllowance(account, "0x11257F61C1225B9839c668b6183Cdf06c4eF3fd4", amount, setPending, infiniteApprove)
        console.log("use matic swap contract")
    }        
    
    await swapcenterContract.methods.swapExactTokenIn(tokenAddress, vault.address, "0x"+amount.toString(16), "0x"+minimumDeposit.toString(16)).send({from:account[0]})
    .on('sent',(payload)=>{
        setPending({"pending":true})
    })
    .on('confirmation', (ConfirmationNum, receipt)=>{
        if (ConfirmationNum === 0) {
            setPending({ "success": true })
            window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false })}), 1500)
        }
            
    })
    .on('error', (error,receipt)=>{
        setPending({"pending":false, "error":true, "errorcode":error.code})
    })
}

// Withdraw
export async function withdrawBase(vault, account, setPending, amount) {
    await vault.vaultContract.methods.withdraw("0x"+vault.baseToken.displayToBigNum(amount).toString(16)).send({from:account[0]})
    .on('sent',(payload)=>{
        setPending({"pending":true})
    })
    .on('confirmation', (ConfirmationNum, receipt) => {
        if (ConfirmationNum === 0) {
            setPending({ "success": true })
            window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false })}), 1500)
        }
    })
    .on('error', (error,receipt)=>{
        setPending({"pending":false, "error":true, "errorcode":error.code})
    })
}


// Get Vehicle List
export async function getVehicleList(contract) {
    var vehicleNum = await contract.methods.investmentVehiclesLength().call()
    var promiseList = []
    for (var i = 0; i < vehicleNum; i++){
        var _promise = contract.methods.getInvestmentVehicle(i).call()
        promiseList.push(_promise)
    }
    var vehicleAddressList = await Promise.all(promiseList)
    return vehicleAddressList
}

// Get Vault value
export async function getValue(vault) {
    if (!window.ethereum) return "-"
    if (!vault.vaultContract) return "-"
    var amount = await vault.vaultContract.methods.totalSupply().call()
    var price = await getTokenPrice(vault.baseToken.address)
    if (price === "unavailable")
        return 0
    amount = BigNumber(amount).shiftedBy(-1 * (parseInt(vault.baseToken.decimal)))
    price = BigNumber(price)
    var value = amount.times(price).toFixed(2)
    return value
}

// Get Vault Capacity
export async function getCapacity(vault) {
    var totalSupply = await vault.vaultContract.methods.totalSupply().call()
    var capacity = await vault.vaultContract.methods.depositCap().call()
    totalSupply = BigNumber(totalSupply)
    capacity = BigNumber(capacity)
    if (totalSupply.div(capacity).times(100).comparedTo(100) === 1)
        return [capacity.toString(), totalSupply.toString(), "100"]
    return [capacity.toString(), totalSupply.toString(), totalSupply.div(capacity).times(100).toString()]
}


// Get Apy
export async function getApy(vaultList) {
    if(!window.ethereum) return "-"
    // get APY (Yearn)
    var yearnAPY = await fetch("https://api.yearn.finance/v1/chains/1/vaults/all").then(
        (_res) => {
            return _res.json()
        })

    vaultList.forEach(async(vault) => {
        var vehicleList = await getVehicleList(vault.vaultContract)
        var promiseList=[]
        vehicleList.forEach(async(vehicleAddr)=> {
            var ivContract = useIVContract(vehicleAddr)
            var _baseAsset = ivContract.methods.baseAsset.call().call()
            promiseList.push(_baseAsset)
        })
        var baseAssetList = await Promise.all(promiseList)
        var apyList = []
        baseAssetList.forEach((baseAsset) => {
            var apy="unavailable"
            yearnAPY.forEach((yearnData) => {
                if (yearnData.token.address === baseAsset) {
                    apy = yearnData.apy.net_apy
                    return
                }
            })
            apyList.push(apy)
        })
        var accApy = 0;
        vehicleList = vehicleList.map((addr, idx) => {
            if (!isNaN(apyList[idx])) accApy += apyList[idx]
            else accApy = "unavailable"
            return ({
                "address": addr,
                "apy": apyList[idx],
            })
        })
        if (!isNaN(accApy)) accApy /= vehicleList.length
        vault.apy = accApy
    })
    return vaultList
}

export async function getApyfromVehicle(yearnAPY, vehicleList) {
    var promiseList=[]
    vehicleList.forEach(async(vehicleAddr)=> {
        var ivContract = useIVContract(vehicleAddr)
        var _baseAsset = ivContract.methods.baseAsset.call().call()
        promiseList.push(_baseAsset)
    })
    var baseAssetList = await Promise.all(promiseList)
    var apyList = []
    baseAssetList.forEach((baseAsset) => {
        var apy="unavailable"
        yearnAPY.forEach((yearnData) => {
            if (yearnData.token.address === baseAsset) {
                apy = yearnData.apy.net_apy
                return
            }
        })
        apyList.push(apy)
    })
    var accApy = 0;
    vehicleList = vehicleList.map((addr, idx) => {
        if (!isNaN(apyList[idx])&&!isNaN(accApy)) accApy += apyList[idx]
        else accApy = "unavailable"
        return ({
            "address": addr,
            "apy": apyList[idx],
        })
    })
    if (!isNaN(accApy)) {
        accApy /= vehicleList.length
        accApy *= 100
    } 
    return accApy;
}

export async function getVaultApy(vault) {
    //console.log(vault)
    if (!window.ethereum) return "-"
    var yearnAPY = await fetch("https://api.yearn.finance/v1/chains/1/vaults/all").then(
        (_res) => {
            return _res.json()
        })
    var vehicleList = await getVehicleList(vault.vaultContract)
    var _firstLevelApy = getApyfromVehicle(yearnAPY, vehicleList)
    
    // selfCompoundingYield Apy
    var selfCompoundingLongAsset = await vault.vaultContract.methods.selfCompoundingLongAsset.call().call()
    var addressCheck = new BigNumber(selfCompoundingLongAsset)
    var accApy
    var scApy
    if (!addressCheck.isZero()) {
        var scVaultConatrct = new useSCContract(selfCompoundingLongAsset)
        var secondLevelVehicleList = await getVehicleList(scVaultConatrct)
        var _secondLevelApy = getApyfromVehicle(yearnAPY, secondLevelVehicleList)
        var Apys = await Promise.all([_firstLevelApy,_secondLevelApy])
        accApy = (!isNaN(Apys[0])) ? Apys[0].toFixed(2)+"% " : "-%"
        scApy = (!isNaN(Apys[1])) ? Apys[1].toFixed(2)+"% " : "-%"
    }
    else {
        let _accApy = await _firstLevelApy
        accApy = (!isNaN(_accApy)) ? _accApy.toFixed(2)+"% " : "-%"
        scApy = "-%"
    }
    
    vault.apy = accApy
    vault.scApy = scApy
    return [accApy,scApy]
}

export async function fundInVault(vaultAddress, vaultContract) {
    let baseAsset = await vaultContract.methods.baseAsset.call().call()
    let tknContract = new useTokenContract(baseAsset)
    let balance = await tknContract.methods.balanceOf(vaultAddress).call()
    return balance
}