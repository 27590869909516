import {newContract} from "./useContract"
import erc20Abi from '../abi/test/mock/MockERC20.json'
import Web3 from "web3"
import { Component } from "react"
import BigNumber from "bignumber.js"

//import { useContract } from "./useContract"
export default class baseCurrency{
    /**
     * create baseCurrency for token info
     * @param {chainId} 
     * @param {decimals} 
     * @param {symbol} 
     * @param {name} 
     */
    constructor(props){
        this.balanceOf = this.balanceOf.bind(this)  
        this.chainId = props.chainId
        this.decimal = props.decimal
        this.symbol = props.symbol
        this.address = props.address
        this.isLPtoken = props.isLPtoken
        this.contract = newContract(this.address,erc20Abi.abi)
    }

    balanceOf = async(account) => {
        return await this.contract.methods.balanceOf(account).call()
    }
    
    strNumToDisplay = (strNum) => {
        //var value = new BigNumber(strNum).shiftedBy(-1*parseInt(this.decimal)).toFixed(5).toString()
        var value = new BigNumber(strNum).shiftedBy(-1*parseInt(this.decimal)).toString()
        return value
    }

    displayToBigNum = (strNum) =>{
        var value = BigNumber(strNum).toFixed(parseInt(this.decimal), 1)
        value = BigNumber(value).shiftedBy(parseInt(this.decimal))
        return value
    }

    displayToFunctionInput = (strNum) => {
        var value = BigNumber(strNum).toFixed(parseInt(this.decimal), 1)
        value = BigNumber(value).shiftedBy(parseInt(this.decimal))
        value = "0x" + value.toString(16)
        return value
    }

    checkAllowance = async(account, vaultAddress, amount, setPending, infiniteApprove) =>{
        var allowed =  await this.contract.methods.allowance(account[0],vaultAddress).call()
        allowed = new BigNumber(allowed).toString()
        if (amount.comparedTo(allowed) > 0)
            if (infiniteApprove) {
                //amount = await this.contract.methods.totalSupply().call()
                amount = BigNumber("115792089237316195423570985008687907853269984665640564039457584007913129639935")
                await this.contract.methods.approve(vaultAddress, "0x"+amount.toString(16)).send({from:account[0]})
                .on('sent',(payload)=>{
                    setPending({"pending":true})
                })
                .on('confirmation', (ConfirmationNum, receipt)=>{
                    if(ConfirmationNum===0)
                        setPending({"pending":false})
                })
                .on('error', (error,receipt)=>{
                    setPending({"pending":false, "error":true, "errorcode":error.code})
                })
            }
            else {
                await this.contract.methods.approve(vaultAddress, "0x"+amount.toString(16)).send({from:account[0]})
                .on('sent',(payload)=>{
                    setPending({"pending":true})
                })
                .on('confirmation', (ConfirmationNum, receipt)=>{
                    if(ConfirmationNum===0)
                        setPending({"pending":false})
                })
                .on('error', (error,receipt)=>{
                    setPending({"pending":false, "error":true, "errorcode":error.code})
                })
            }
    }
    
    displayCompare = (str1, str2) =>{
        str1 = new BigNumber(str1).shiftedBy(parseInt(this.decimal))
        str2 = new BigNumber(str2).shiftedBy(parseInt(this.decimal))
        return str1.comparedTo(str2)
    }
}