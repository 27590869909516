import { Component } from "react"
import { Link } from "react-router-dom"
import logo from './img/longlogo.svg'
import discord from './img/discord.svg'
import twitter from './img/twitter.svg'
import github from './img/github.svg'
import medium from './img/medium.svg'

import AlchemyBadge from "./AlchemyBadge"

import "./css/SideBar.scss" 

export default class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount = () => {
        this.setState((prevState, props) => {
            return ({'active':props.page})
        })
    }

    componentDidUpdate = (prevprops) => {
        if (this.props !== prevprops) {
            this.setState((prevState, props) => {
                return ({'active':props.page})
            })
        }
    }

    render() {
        return (
            <div id="side-container">
                <div id="logo">
                    
                    <div id="logo-img">
                        <Link to="/">
                            <img src={logo} alt="LongLong Finance" />
                        </Link>
                    </div>
                    <div id="logo-text">
                        <Link to="/">
                            LongLong
                        </Link>
                    </div>
                    
                </div>
                <ul id="primary-nav">
                    <PageBtn path="/long" page="Long Vault" addClass={this.state.active === "Long Vault" ? " active" : ""} />
                    
                    {/*
                        <PageBtn path="/hodlandfeed" page="Hodl & Feed" addClass={this.state.active === "Hodl & Feed Vault" ? " active" : ""} />
                        <PageBtn path="/" page="Home" addClass={this.state.active==="Home"? " active":""} onClick={()=>{this.setState({active:"Home"})}}/>
                        <PageBtn path="/shieldmining" page="Shield Mining" addClass={this.state.active === "Shield" ? " active" : ""} onClick={() => { this.setState({ active: "Shield" }) }} />
                        <PageBtn path="/farm" page="Farm" addClass={this.state.active==="Farm"? " active":""} onClick={()=>{this.setState({active:"Farm"})}}/>
                        <PageBtn path="/airdrop" page="Airdrop" addClass={this.state.active==="Airdrop"? " active":""} onClick={()=>{this.setState({active:"Airdrop"})}}/>
                    */}
                </ul>
                <ul id="social-nav">
                    <SocialBtn link="https://twitter.com/longlongfinance" logo={twitter}/>
                    <SocialBtn link="https://discord.com/invite/DnRGW7fpyJ" logo={discord}/>
                    <SocialBtn link="http://github.com/longlong-finance" logo={github}/>
                    <SocialBtn link="https://medium.com/@longlong.finance" logo={medium}/>
                </ul>
                <AlchemyBadge />
                <div id="user-btn">
                    <a href="https://docs.longlong.finance/userguide/" target="_blank">
                        User Guide
                    </a>
                </div>
                <div id="doc-btn">
                    <a href="https://docs.longlong.finance/" target="_blank">
                        Documentation
                    </a>
                </div>
            </div>
        )
    }

}

const PageBtn=(props)=>{
    return(
        <li className={"page-btn"+props.addClass} onClick={props.onClick}>
            <Link to={props.path}>
                <img src={logo} alt="LongLong finance" />
                <div className="side-btn-text">{props.page}</div>
            </Link>
        </li>
    )
}

const SocialBtn=(props)=>{

    return(
        <li className="social-btn">
            <a href={props.link} target="_blank">
                <img src={props.logo} alt="social media" />
            </a>
        </li>
    )
}