import { useEffect, useState } from "react"
import longlogo from "./img/longlogo.svg"
import "./css/Loading.scss"
export default function Loading(props) {

    if (props.success === true) {
        return (
            <div id="pending-container">
                <img src={longlogo} alt="long long" />
                <div id="pending-text">
                    success! transaction completed
                </div>
            </div>
        )
    }
    return (
        <div id="pending-container">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            <div id="pending-text">
                {props.text}
            </div>
        </div>
    )
}