import { Component } from "react";
import metamask from "./img/metamask.svg"
import Loading from "./Loading";
import "./css/ConnectWarningCard.scss"
import MetaMaskOnBoarding from "@metamask/onboarding"

export default class ConnectWarningCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "pending": false,
        }
        if (!this.props.metaMaskInstalled) {
            this.prompt = "please install metamask to continue"
            this.btnPrompt = "install MetaMask"
            this.onClickFunc = this.installMetaMask
        }
        else if (!this.props.metaMaskConnected) {
            this.prompt = "please connect metamask to continue"
            this.btnPrompt = "connect MetaMask"
            this.onClickFunc = this.connectMetaMask
        }
    }

    connectMetaMask = async () => {
        try {
            this.setState({ pending: true })
            await window.ethereum.request({ method: "eth_requestAccounts" })
        } catch (error) {
            alert(error.message)
        }
        this.setState({ pending: false })
        const accounts = await window.ethereum.request({ method: "eth_accounts" })
        this.setState({
            metaMaskConnected: Boolean(accounts && accounts.length > 0),
            accounts: accounts,
        }, this.saveToLocal)
    }

    installMetaMask = () => {
        const currentUrl = new URL(window.location.href)
        const forwarderOrigin = currentUrl.hostname === 'localhost' ?
            'http://localhost:3000' : undefined
        var onboarding = new MetaMaskOnBoarding({ forwarderOrigin })
        onboarding.startOnboarding();
    }

    render() {
        if (!this.props.metaMaskInstalled) {
            this.prompt = "please install metamask to continue"
            this.btnPrompt = "install MetaMask"
            this.onClickFunc = this.installMetaMask
        }
        else if (!this.props.metaMaskConnected) {
            this.prompt = "please connect metamask to continue"
            this.btnPrompt = "connect MetaMask"
            this.onClickFunc = this.connectMetaMask
        }
            return (
                <div className="card-background">
                    <div className="card-container connect-card" ref={this.wrapperRef}>
                        {
                            (this.state.pending) &&
                            <Loading text="connecting"></Loading>
                        }
                        {
                            (!this.state.pending) &&
                            <>
                                <img src={metamask} alt="metamask icon" />
                                <div id="connect-text">{this.prompt}</div>
                                <input className="btn-2" type="button" value={this.btnPrompt} onClick={this.onClickFunc} />
                            </>
                        }
                    </div>
                </div>
            )
    }
}