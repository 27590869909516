import { Component, createRef, useState } from "react";
import Toggle from "./Toggle";
import CheckBox from "./CheckBox";
import { getDepositData, depositBase, withdrawBase, getCapacity, depositToken } from "../../utils/vaultInterface";
import { claimAllReward, claimIndividualReward, getClaimableLongAmount, getClaimableRewardAmount } from "../../utils/rewardInterface";
import BigNumber from "bignumber.js";
import Loading from "../Loading";
import Error from "../Error";
import sortdown from "../img/sort-down.svg"
import "../css/Tooltip.scss"
import "./css/AssetCard.scss"
import baseCurrency from "../../utils/baseCurrency";
export default class AssetCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "mode": "deposit",
            "depositData": {
                availableBase: 0,
                depositedBase: 0,
            },
            "claimData": {
                claimableLong: 0,
                claimableReward: [],
            },
            "showExceedWarning": false,
            "pending": false,
            "account": this.props.account,
            "depositamount": "",
            "withdrawamount": "",
            "showCapacityWarning": false,
            "showDropList": false,
            "depositToken": this.props.vault.baseToken.address,
            "depositTokenSymbol": this.props.vault.baseToken.symbol,
            "showSlipageSelection": false,
            "showAvailableDepositToken": false,
            "slipage": "0.5",
            "infiniteApprove": true,
            "percisionCheckDigit": this.props.vault.baseToken.decimal,
        }
        this.vault = props.vault
        this.wrapperRef = createRef();
    }
    toggleMode = () => {
        if (this.state.mode === "withdraw") {
            this.setState({
                "mode": "deposit"
            })
        }
        if (this.state.mode === "deposit") {
            this.setState({
                "mode": "withdraw"
            })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.detectClick)
        document.addEventListener("keydown", this.detectKey, false)
        this.displayData()
    }

    async componentDidUpdate(prevprops, prevstate) {
        if (this.props.account != prevprops.account) {
            await this.setState({
                "account": this.props.account,
                "depositamount": "",
                "withdrawamount": "",
                "showCapacityWarning": false,
            })
            await this.displayData()
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.detectClick)
        document.removeEventListener("keydown", this.detectKey, false)
    }

    displayData = () => {
        const _depositData = getDepositData(this.props.vault, this.state.account)
        const _longData = getClaimableLongAmount(this.props.vault, this.state.account)
        const _rewardData = getClaimableRewardAmount(this.props.vault, this.state.account)
        const _getCapacity = getCapacity(this.vault)

        Promise.all([_depositData, _longData, _rewardData, _getCapacity]).then((res) => {
            this.setState({
                "depositData": res[0],
                "claimData": {
                    "claimableLong": res[1],
                    "claimableReward": res[2],
                },
                "capacityDenominator": Number(this.vault.baseToken.strNumToDisplay(res[3][0])).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                "capacityNumerator": Number(this.vault.baseToken.strNumToDisplay(res[3][1])).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                "capacity": Number(res[3][2]).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            })
        })
    }

    keyPressDetect = (e) => {
        var allowedChars = "0123456789."
        if ((e.target.value.includes(".") && e.key == ".")) {
            e.preventDefault();
        }
        else if (!allowedChars.includes(e.key)) {
            e.preventDefault();
        }
    }


    detectClick = (e) => {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            this.props.close()
        }
    }

    detectKey = (e) => {
        if (e.key === "Escape")
            this.props.close()
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    onDepositChange = (e) => {
        //console.log(this.state.percisionCheckDigit)
        var depositValue = BigNumber(e.target.value).shiftedBy(1 * this.state.percisionCheckDigit)
        if (depositValue.minus(depositValue.toFixed(0)).abs().comparedTo(0) < 1) {
            this.setState({
                [e.target.name]: e.target.value,
            })
        }
        else {
            e.preventDefault();
        }
    }

    claimToken = async (token, account) => {
        //console.log("claimToken", token, account)
        await claimIndividualReward(this.props.vault, token, account, this.updateState)
        this.displayData()
    }

    claimAll = async (account) => {
        //console.log("claimAll", account)
        await claimAllReward(this.props.vault, account, this.updateState)
        this.displayData()
    }

    deposit = async (account) => {
        //console.log("deposit", account)
        if (this.state.depositamount && this.state.depositamount != 0) {
            if (this.state.depositToken === this.props.vault.baseToken.address) {
                await depositBase(this.props.vault, account, this.updateState, this.state.depositamount, this.setCapacityWarning, this.state.infiniteApprove)
                this.displayData()
            }
            else {
                await depositToken(this.props.vault, this.state.depositToken, account, this.updateState, this.state.depositamount, this.setCapacityWarning, this.state.slipage, this.state.infiniteApprove)
            }

        }
    }

    withdraw = async (account, depositedBase) => {
        if (this.state.withdrawamount && this.state.withdrawamount != 0) {
            if (this.props.vault.baseToken.displayCompare(this.state.withdrawamount, depositedBase) > 0)
                this.setState({
                    "showExceedWarning": true,
                })
            else {
                this.setState({
                    "showExceedWarning": false,
                })
                await withdrawBase(this.props.vault, account, this.updateState, this.state.withdrawamount)
                this.displayData()
            }
        }
    }

    updateState = (obj) => {
        this.props.updateAppState(obj)
        this.setState(obj)
    }

    setWithdrawRatio = (ratio) => {
        var withdrawamount = new BigNumber(this.state.depositData.depositedBase)
        withdrawamount = withdrawamount.multipliedBy(ratio).toFixed(parseInt(this.vault.baseToken.decimal))
        this.setState({
            withdrawamount: withdrawamount
        })
    }

    setCapacityWarning = (value) => {
        this.setState({
            "showCapacityWarning": value
        })
    }

    setDepositToken = async (token) => {
        var differentThanBase = (token.address == this.vault.baseToken.address) ? false : true
        var balance = 0;
        if (differentThanBase) {
            var token = new baseCurrency(token)
            balance = await token.balanceOf(this.state.account[0])
            balance = token.strNumToDisplay(balance)
        }
        this.setState({
            "depositToken": token.address,
            "depositTokenSymbol": token.symbol,
            "showDropList": false,
            "showSlipageSelection": differentThanBase,
            "showCapacityWarning": false,
            "showAvailableDepositToken": differentThanBase,
            "depositTokenBalance": balance,
            "percisionCheckDigit": token.decimal,
            "depositamount": "",
        })
    }

    setSlipage = (slipage) => {
        this.setState({
            "slipage": slipage
        })
    }

    useMax = () => {
        this.setState({
            "depositamount": this.state.depositToken === this.vault.baseToken.address ? this.state.depositData.availableBase : this.state.depositTokenBalance,
        })
    }

    render() {
        var depositbase = parseFloat(this.state.depositData.depositedBase).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })
        var availablebase = parseFloat(this.state.depositData.availableBase).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })
        var claimablelong = parseFloat(this.state.claimData.claimableLong).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })
        var availabledeposit = parseFloat(this.state.depositTokenBalance).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })
        var scApy = (this.vault.scApy!=="-%") ? " (APY " + this.vault.scApy + ")" : ""

        if (this.state.mode === "withdraw") {
            return (
                <div className="card-background">
                    <div className="card-container" ref={this.wrapperRef}>
                        <div className="title-row">
                            <div id="title">{this.vault.name}</div>
                            <div id="toggle-container">
                                <div id="left-text">{this.state.mode}</div>
                                <Toggle changed={(this.state.mode === "withdraw") ? false : true} toggleMode={this.toggleMode} />
                            </div>
                        </div>
                        {
                            (this.state.pending === true) && <Loading text="pending execution" success={this.state.success} />
                        }
                        {
                            (this.state.pending !== true && this.state.error == true) &&
                            <Error errorcode={this.state.errorcode} setPending={this.updateState} />
                        }
                        {
                            (this.state.pending !== true && this.state.error !== true) &&
                            <div>
                                <div className="asset-info">
                                    <div className="asset-title">{"deposited " + this.vault.baseToken.symbol.toUpperCase()}</div>
                                    <div className="asset-value align-right">{depositbase}</div>
                                </div>
                                <div id="withdraw">
                                    <input type="text" name="withdrawamount" id="withdraw-input"
                                        placeholder={depositbase}
                                        value={this.state.withdrawamount}
                                        onChange={this.onInputChange}
                                        onKeyPress={this.keyPressDetect} />
                                    <input className="btn-1" type="button" value="Withdraw" onClick={() => { this.withdraw(this.props.account, this.state.depositData.depositedBase) }} />
                                </div>
                                {
                                    (this.state.showExceedWarning) &&
                                    <div id="exceed-warning">Can't withdraw amount larger than the deposited amount!</div>
                                }
                                <div id="withdraw-ratio">
                                    <input className="ratio-btn btn-2" type="button" value="25%" onClick={() => { this.setWithdrawRatio(0.25) }} />
                                    <input className="ratio-btn btn-2" type="button" value="50%" onClick={() => { this.setWithdrawRatio(0.5) }} />
                                    <input className="ratio-btn btn-2" type="button" value="75%" onClick={() => { this.setWithdrawRatio(0.75) }} />
                                    <input className="ratio-btn btn-2" type="button" value="100%" onClick={() => { this.setWithdrawRatio(1) }} />
                                </div>
                                <div id="title2">Yields</div>
                                <div className="asset-info">
                                    <div className="asset-title">{this.vault.longToken.symbol.toUpperCase()}
                                        <div className="longapy">
                                            {scApy}
                                        </div>
                                    </div>
                                    <div className="asset-value align-right">{claimablelong}</div>
                                    <input className="btn-2" type="button" value="Claim" onClick={(e) => { this.claimToken(this.vault.longToken, this.props.account) }} />
                                </div>
                                {
                                    this.state.claimData.claimableReward.map((item) => {
                                        var claimable = parseFloat(item.claimable).toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                                        return (
                                            <div className="asset-info">
                                                <div className="asset-title">
                                                    {"Claimable " + item.symbol.toUpperCase()}
                                                </div>
                                                <div className="asset-value align-right">{claimable}</div>
                                                <input className="btn-2" type="button" value="Claim" onClick={(e) => { this.claimToken(item, this.props.account) }} />
                                            </div>
                                        )
                                    })
                                }
                                <input className="btn-1" id="claim-all-btn" type="button" value="Claim All Rewards" onClick={(e) => { this.claimAll(this.props.account) }} />
                            </div>
                        }

                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="card-background">
                    <div className="card-container" ref={this.wrapperRef}>
                        <div className="title-row">
                            <div id="title">{this.vault.name}</div>
                            <div id="toggle-container">
                                <div id="left-text">{this.state.mode}</div>
                                <Toggle changed={(this.state.mode === "withdraw") ? true : false} toggleMode={this.toggleMode} />
                            </div>
                        </div>
                        {
                            (this.state.pending === true) && <Loading text="pending execution" success={this.state.success} />
                        }
                        {
                            (this.state.pending !== true && this.state.error == true) &&
                            <Error errorcode={this.state.errorcode} setPending={this.updateState} />
                        }
                        {
                            (this.state.pending !== true && this.state.error != true) &&
                            <div>
                                <ProgressBar percentage={this.state.capacity} tipText={this.state.capacityNumerator + " / " + this.state.capacityDenominator + " " + this.vault.baseToken.symbol.toUpperCase() + " full"} />
                                <div className="asset-info">
                                    <div className="asset-title">{"available " + this.vault.baseToken.symbol.toUpperCase()}</div>
                                    <div className="asset-value align-right">{availablebase}</div>
                                </div>
                                {
                                    (this.state.showAvailableDepositToken) &&
                                    <div className="asset-info">
                                        <div className="asset-title">{"available " + this.state.depositTokenSymbol.toUpperCase()}</div>
                                        <div className="asset-value align-right">{availabledeposit}</div>
                                    </div>
                                }

                                <div className="asset-info">
                                    <div className="asset-title">{"deposited " + this.vault.baseToken.symbol.toUpperCase()}</div>
                                    <div className="asset-value align-right">{depositbase}</div>
                                </div>
                                <div className="deposit-input">
                                    <div id="input-field">
                                        <div id="select" onClick={() => { this.setState((prevstate) => ({ "showDropList": !prevstate.showDropList })) }}>
                                            <img src={require("../img/" + this.state.depositTokenSymbol.toUpperCase() + ".png").default} alt="" />
                                            {this.state.depositTokenSymbol.toUpperCase()}
                                            <img id="downward-tri" src={sortdown} alt="" />
                                        </div>
                                        <input type="text" name="depositamount" id="deposit-input"
                                            placeholder={this.state.depositToken === this.vault.baseToken.address ? availablebase : availabledeposit}
                                            value={this.state.depositamount}
                                            onChange={this.onDepositChange}
                                            onKeyPress={this.keyPressDetect} />
                                        <input className="max-btn" type="button" value="Max" onClick={this.useMax} />
                                        {(this.state.showDropList) &&
                                            <DropDown list={this.vault.subTokenList} baseToken={this.vault.baseToken} setDepositToken={this.setDepositToken} />
                                        }
                                    </div>
                                    <input className="btn-1" type="button" value="Deposit" onClick={() => { this.deposit(this.props.account) }} />
                                </div>
                                <div>
                                    <CheckBox text="inifinite approve" checked={this.state.infiniteApprove} setCheck={() => { this.setState((prevstate) => ({ "infiniteApprove": !prevstate.infiniteApprove })) }} />
                                    {
                                        (this.state.showSlipageSelection) &&
                                        <SlipageSelection setSlipage={this.setSlipage} />
                                    }
                                </div>

                                {
                                    (this.state.showCapacityWarning) &&
                                    <div id="exceed-warning">Can't deposit, The amount exceed vault capacity</div>
                                }
                                <div id="bootstraping-anouncement">
                                    * LongLong Finance is in its bootstrapping period now.  The funds you deposited during this period will never be charged for the withdraw fee in the future.
                                </div>
                            </div>
                        }

                    </div>
                </div>
            )
        }
    }

}

export function ProgressBar(props) {
    var percentage = props.percentage + "%"
    //console.log(percentage)
    return (
        <div className="progress-bar-container ">
            <div id="text" className="tooltip-container" >vault capacity {percentage}
                <span className="tooltip">
                    {props.tipText}
                </span>
            </div>
            <div className="light-grey">
                <div className="yellow" style={{ height: '.5em', width: percentage }}></div>
            </div>

        </div>
    )
}

export class DropDown extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        if (this.props.list.length > 0) {
            return (
                <div id="dropdown-container">
                    <DropDownOption token={this.props.baseToken} setDepositToken={this.props.setDepositToken} />
                    {
                        this.props.list.map((item, key) => {
                            //console.log(item)
                            return (
                                <DropDownOption token={item} setDepositToken={this.props.setDepositToken} />
                            )
                        })
                    }
                </div>
            )
        }
        return null
    }

}

export class DropDownOption extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div id="option-container" onClick={() => { this.props.setDepositToken(this.props.token) }}>
                <img src={require("../img/" + this.props.token.symbol.toUpperCase() + ".png").default} alt="" />
                {this.props.token.symbol.toUpperCase()}
            </div>
        )
    }

}

export function SlipageSelection(props) {
    const [active, setactive] = useState("0.5")

    const setSlipage = (value) => {
        setactive(value)
        props.setSlipage(value)
    }
    return (
        <div id="slipage-container">
            max slipage :
            <input className={(active === "0.5") ? "active" : ""} type="button" value="0.5%" id="slipage-btn" onClick={() => { setSlipage("0.5") }} />
            <input className={(active === "1") ? "active" : ""} type="button" value="1%" id="slipage-btn" onClick={() => { setSlipage("1") }} />
            <input type="text" name="" id="slipage-input" value={active} onChange={(e) => { setSlipage(e.target.value) }} />
            <div id="percentage-tag">%</div>
        </div>
    )
}