import tokenList from "../address/token.json"
import { newContract } from "../utils/useContract"
import erc20Abi from '../abi/test/mock/MockERC20.json'
import lptknAbi from "../abi/lptokenabi.json"
import Web3 from "web3"
import BigNumber from "bignumber.js"

function usetokenContract(address) {
    return newContract(address, lptknAbi)
}

export async function getCoinGekoPrice( addr, chainId ) {
    if (chainId === 137) {
        var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/polygon-pos/contract/${addr}`).then(
            async (res) => {
                res = await res.json()
                return res.market_data.current_price.usd //number
            }
        )
    }
    else {
        var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${addr}`).then(
            async (res) => {
                res = await res.json()
                return res.market_data.current_price.usd //number
            }
        )
    }
    return curPrice
}

export async function getEthPrice() {
    var curPrice = await fetch(`https://api.coingecko.com/api/v3/coins/ethereum`).then(
        async (res) => {
            res = await res.json()
            return res.market_data.current_price.usd
        }
    )
    return curPrice
}

export const getLPTokenPrice = async ( addr, chainId ) => {
    // get total supply
    //this.useTokenContract = useTokenContract

    var tokenContract = await usetokenContract(addr)
    var totalSupply = await tokenContract.methods.totalSupply().call()

    // for token in subtoken list
    var totalValue = new BigNumber(0)
    var subtokens = tokenList[addr].subtokens
    await Promise.all(subtokens.map(async (token) => {
        var _price = getTokenPrice(token, chainId)
        if (token !== "ETH")
            var subtokenContract = usetokenContract(token)
        var _amount = (token === "ETH") ? getETHbalance(tokenList[addr].pooladdress) : subtokenContract.methods.balanceOf(tokenList[addr].pooladdress).call()
        var value = await Promise.all([_price, _amount]).then((res) => {
            var price = new BigNumber(res[0])
            var amount = new BigNumber(res[1]).shiftedBy(-1 * parseInt(tokenList[token].decimal))
            var value = price.times(amount)
            return value
        })
        totalValue = totalValue.plus(value)
    }))
    totalSupply = new BigNumber(totalSupply).shiftedBy(-1 * parseInt(tokenList[addr].decimal))
    return totalValue.div(totalSupply).toString()
}

export async function getTokenPrice(addr) {
    if (tokenList[addr].chainId !== 1 &&  tokenList[addr].chainId!==137)
        return "unavailable"
    // return in string
    var price
    if (addr === "ETH") {
        price = await getEthPrice()
    }
    else if (tokenList[addr] && tokenList[addr].isLPtoken) {
        price = await getLPTokenPrice(addr, tokenList[addr].chainId)
    }
    else {
        price = await getCoinGekoPrice(addr, tokenList[addr].chainId)
    }
    return price
}

export async function getETHbalance(addr) {
    var web3 = new Web3(window.ethereum)
    var amount = await web3.eth.getBalance(addr)
    return amount
}
