import { Component } from "react"
import "./css/Vault.scss"
import AssetCard from "./Vault/AssetCard"
import ConnectWarningCard from "./ConnectWarningCard"
import VaultItem from "./VaultItem"
import vaultList from "../utils/vaultList"
import { Helmet } from "react-helmet"
import poweredByPolygon from "../components/img/poweredpolygon.png"
    
export default class Vault extends Component {
    constructor(props) {
        super(props)
        this.props.setPage(this.props.page)
        this.updateAppState = this.props.updateAppState
        this.vaultList = []
        this.state = {
            "vaultList": this.vaultList,
            "valueLocked": "-"
        }
        this.vaultTitle = {
            "id": -1,
            "name": "Vault Name",
            "baseToken": "BaseToken",
            "longToken": "LongToken",
            "apy": "Apy",
            "value": "Value",
        }
    }

    addTotalValue = (value) => {
        //this.totalValue = value
        var _value = this.state.valueLocked
        if (this.state.valueLocked === "-") {
            _value = parseFloat(value)
        }
        else {
            _value += parseFloat(value)
        }
        // parse value
        this.setState({
            "valueLocked": _value
        })
    }

    componentDidMount = async () => {
        var vl = new vaultList(this.props.type)
        this.vaultList = await vl.getVaultList()
        this.props.setPage(this.props.page)
        this.setState(prevstate => ({ "vaultList": this.vaultList }))
    }

    componentDidUpdate = async (prevprops) => {
        if (prevprops.accountState.chainId != this.props.accountState.chainId) {
            this.setState({ "valueLocked": "-" })
        }
        if (prevprops.type != this.props.type) {
            var vl = new vaultList(this.props.type)
            this.vaultList = await vl.getVaultList()
            this.setState({ "valueLocked": "-" })
            this.props.setPage(this.props.page)
            this.setState(prevstate => ({ "vaultList": this.vaultList }))
        }
    }

    onVaultItemClicked = (e) => {
        //display popup card
        this.setState({
            "displayCard": true,
            "vault": this.vaultList[e.target.dataset.vault], //will change to get the specific vault from the given vult list and vault id
        })
    }

    onCardBackgroundClicked = (e) => {
        this.setState({
            "displayCard": false,
        })
    }

    render() {
        //console.log("rerender vault",this.props.accountState)
        const account = this.props.accountState.account
        return (
            <>
                {(!this.props.accountState.metaMaskConnected || !this.props.accountState.metaMaskInstalled) &&
                    <ConnectWarningCard updateAppState={this.props.updateAppState}
                        metaMaskInstalled={this.props.accountState.metaMaskInstalled}
                        metaMaskConnected={this.props.accountState.metaMaskConnected} />
                }
                <div className="page-container content-container">
                    <Helmet>
                        <title>LongLong Finance - vault</title>
                        <meta name="description" content="Start to grow your crypto assets by deposit to the Long Vault. Diversifying your principal and yield into different assets with LongLong Finance." />
                    </Helmet>
                    <p id="bootstraping-anouncement">
                        * LongLong Finance is in its bootstrapping period now. <br />The funds you deposited during this period will never be charged for the withdraw fee in the future. <br />
                        * Note that the protocol has not been audited yet. Please use at your own risk.
                    </p>
                    <TotalValueLocked value={"$ " + this.state.valueLocked.toLocaleString(undefined)} />
                    <VaultItem vaultInfo={this.vaultTitle} isTitle={true} addClass="title" />
                    {
                        this.state.vaultList.map((vault, idx) => {
                            if (vault.vaultContract && vault.chainId == this.props.accountState.chainId)
                                return <VaultItem key={idx} vaultInfo={vault} onClick={this.onVaultItemClicked} addTotalValue={this.addTotalValue} />
                        })
                    }
                    {
                        (this.state.displayCard) &&
                        <AssetCard vault={this.state.vault} close={this.onCardBackgroundClicked} account={account} updateAppState={this.props.updateAppState} />
                    }
                    {
                        (this.props.accountState.chainId === "0x89") &&
                        <a href="https://polygon.technology/" target="_blank">
                            <img id="poweredbypolygon" src={poweredByPolygon} alt="powered by polygon" />
                        </a>
                    }
                </div>
            </>
        )
    }
}

export const TotalValueLocked = (props) => {
    return (
        <div id="value-locked-container">
            <div id="value-locked-title">Total Value Locked</div>
            <div id="value-locked-value">{props.value}</div>
        </div>
    )
}


