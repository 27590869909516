import longvaultlist from "../address/longVaults.json"
import hodlfeedvaultlist from "../address/hodlfeedVaults.json"
import baseCurrency from "./baseCurrency"
import tokenlist from "../address/token.json"
import { useVaultContract, useRewardContract, useIVContract } from "./useContract"
import { getVehicleList } from "./vaultInterface"
export default class vaultList{
    constructor(type) {
        if (type === "hodl")
            this.vaultlist = hodlfeedvaultlist
        else
            this.vaultlist = longvaultlist
        //console.log(this.vaultlist)
    }

    async getYearnApy() {
        this.yearnAPY = await fetch("https://api.yearn.finance/v1/chains/1/vaults/all").then(
        (_res) => {
            return _res.json()
            })
    }

    async createVault() {
        var VaultListObj = []
        for (var vault in this.vaultlist) {
            var BaseToken = new baseCurrency(tokenlist[this.vaultlist[vault].baseToken])
            var LongToken = new baseCurrency(tokenlist[this.vaultlist[vault].longToken])
            var RewardToken = []
            this.vaultlist[vault].rewardToken.forEach((rewardtoken) => {
                RewardToken.push(new baseCurrency(tokenlist[rewardtoken]))
            })
            //console.log(this.vaultlist[vault].address)
            var VaultContract = new useVaultContract(this.vaultlist[vault].address)
            //var VaultContract = useVaultContract
            //console.log(VaultContract)
            var RewardContract = await new useRewardContract(this.vaultlist[vault].msnx)
            var subTokenList = []
            if (this.vaultlist[vault].acceptSubtoken) {
                subTokenList = this.vaultlist[vault].subToken
                subTokenList = subTokenList.map(
                    (tkn) => {
                        return tokenlist[tkn]
                    }
                )
            }
            //var RewardContract = useRewardContract
            VaultListObj.push({
                "id": this.vaultlist[vault].id,
                "name": this.vaultlist[vault].name,
                "chainId": this.vaultlist[vault].chainId,
                "baseToken": BaseToken, // pass in Token object here
                "longToken": LongToken, // pass in Token object here
                "baseTokenLink": this.vaultlist[vault].baseTokenLink,
                "longTokenLink": this.vaultlist[vault].longTokenLink,
                "rewardToken": RewardToken, // pass in List of Token object here
                "apy": "-",
                "value": "-",
                "address": this.vaultlist[vault].address,
                "vaultContract": VaultContract,
                "rewardContract": RewardContract,
                "accepSubToken":this.vaultlist[vault].accepSubToken,
                "subTokenList":subTokenList
            })
        }
        return VaultListObj
    }

    async getApy(vaultList) {
        vaultList.forEach(async(vault) => {
            var vehicleList = await getVehicleList(vault)
        var promiseList=[]
        vehicleList.forEach(async(vehicleAddr)=> {
            var ivContract = useIVContract(vehicleAddr)
            var _baseAsset = ivContract.methods.baseAsset.call().call()
            promiseList.push(_baseAsset)
        })
        var baseAssetList = await Promise.all(promiseList)
        var apyList = []
        baseAssetList.forEach((baseAsset) => {
            var apy="unavailable"
            this.yearnAPY.forEach((yearnData) => {
                if (yearnData.token.address === baseAsset) {
                    apy = yearnData.apy.net_apy
                    return
                }
            })
            apyList.push(apy)
        })
        var accApy = 0;
        vehicleList = vehicleList.map((addr, idx) => {
            if (!isNaN(apyList[idx])) accApy += apyList[idx]
            else accApy = "unavailable"
            return ({
                "address": addr,
                "apy": apyList[idx],
            })
        })
        if (!isNaN(accApy)) accApy /= vehicleList.length
        vault.apy = accApy
        })
    }

    async getVaultList() {
        await this.getYearnApy()
        var VaultList = await this.createVault()
        //VaultList = await this.getApy(VaultList)
        return VaultList
    }
}