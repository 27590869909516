import BigNumber from "bignumber.js"
import baseCurrency from "./baseCurrency"
import { useSCContract, useTokenContract } from "./useContract"

// get claimable long token data
export async function getClaimableLongAmount(vault, account) {
    const longAddress = vault.longToken.address
    var selfCompoundingLongAsset = await vault.vaultContract.methods.selfCompoundingLongAsset.call().call()
    var addressCheck = new BigNumber(selfCompoundingLongAsset)
    if (addressCheck.isZero()) {
        var earnedLong = await vault.rewardContract.methods.earned(longAddress, account[0]).call()
            .catch((err) => { console.log(err) })
        earnedLong = vault.longToken.strNumToDisplay(earnedLong)
        return earnedLong
    }
    else {
        var scContract = new useSCContract(selfCompoundingLongAsset) //create selfcompounding Contract with selfCompoundingLongAsset
        var scbalance = await vault.rewardContract.methods.earned(selfCompoundingLongAsset, account[0]).call()
        var earnedLong = await scContract.methods.shareToBaseAsset(scbalance).call()
        earnedLong = vault.longToken.strNumToDisplay(earnedLong)
        return earnedLong
    }

}

// get claimable award token data
export async function getClaimableRewardAmount(vault, account) {
    const rewardList = vault.rewardToken
    const rewardAmounts = await Promise.all(rewardList.map(async (item) => {
        var res = await vault.rewardContract.methods.earned(item.address, account[0]).call()
        var res = item.strNumToDisplay(res)
        item['claimable'] = res
        return (item)
    }))
    return rewardAmounts
}


// Claim individual reward
export async function claimIndividualReward(vault, token, account, setPending) {
    if (token.address === vault.longToken.address) {
        var selfCompoundingLongAsset = await vault.vaultContract.methods.selfCompoundingLongAsset.call().call()
        var addressCheck = new BigNumber(selfCompoundingLongAsset)
        if (addressCheck.isZero()) {
            await vault.rewardContract.methods.getReward(token.address).send({ from: account[0] })
                .on('sent', (payload) => {
                    setPending({ "pending": true })
                })
                .on('confirmation', (ConfirmationNum, receipt) => {
                    if (ConfirmationNum === 0) {
                        setPending({ "success": true })
                        window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false }) }), 1500)
                    }
                })
                .on('error', (error, receipt) => {
                    alert(error.message)
                    setPending({ "pending": false })
                })
        }
        else {
            await vault.rewardContract.methods.getReward(selfCompoundingLongAsset).send({ from: account[0] })
                .on('sent', (payload) => {
                    setPending({ "pending": true })
                })
                .on('confirmation', (ConfirmationNum, receipt) => {
                    if (ConfirmationNum === 0) {
                        setPending({ "success": true })
                        window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false }) }), 1500)
                    }
                })
                .on('error', (error, receipt) => {
                    alert(error.message)
                    setPending({ "pending": false })
                })
        }
    }
    else {
        await vault.rewardContract.methods.getReward(token.address).send({ from: account[0] })
                .on('sent', (payload) => {
                    setPending({ "pending": true })
                })
                .on('confirmation', (ConfirmationNum, receipt) => {
                    if (ConfirmationNum === 0) {
                        setPending({ "success": true })
                        window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false }) }), 1500)
                    }
                })
                .on('error', (error, receipt) => {
                    alert(error.message)
                    setPending({ "pending": false })
                })
    }

}

// Claim all Rewards
//getAllRewards
export async function claimAllReward(vault, account, setPending) {

    vault.rewardContract.methods.getAllRewards().send({ from: account[0] })
        .on('sent', (payload) => {
            setPending({ "pending": true })
        })
        .on('confirmation', (ConfirmationNum, receipt) => {
            if (ConfirmationNum === 0) {
                setPending({ "success": true })
                window.setTimeout((() => { setPending({ "pending": false }); setPending({ "success": false }) }), 1500)
            }
        })
        .on('error', (error, receipt) => {
            alert(error.message)
            setPending({ "pending": false })
        })
}
