import "./css/Toggle.scss"
const Toggle = (props) =>{
    return(
        <div id="toggle-switch">
            <input type="checkbox" onChange={props.toggleMode} defaultChecked></input>
            <span className="slider round"></span>
        </div>
    )
} 

export default Toggle