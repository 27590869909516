import "./css/ChainWarningCard.scss"
import metamask from "./img/metamask.svg"
export default function ChainWarningCard() {
    return (
        <div className="card-background">
            <div className="card-container connect-card">
                <img src={metamask} alt="metamask icon" />
                <div id="connect-text">You're currently on an unsupport chain <br/> please switch to Ethereum mainnet or Polygon to continue.</div>
            </div>
        </div>
    )
}