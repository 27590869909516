import { Component } from "react"
import vaultAbi from "../abi/VaultUpgradeable/VaultUpgradeable.json"
import erc20Abi from "../abi/test/mock/MockERC20.json"
import Web3 from "web3"
import BigNumber from "bignumber.js"
import contractAddress from "../address/contract.json"
import vaultList from "../utils/vaultList"
import { getCoinGekoPrice, getEthPrice, getLPTokenPrice, getETHbalance, getTokenPrice } from "../utils/tokenPrice"
import ChangeChainCard from "./ChangeChainCard"
 

export default class Test extends Component {
    constructor(props) {
        super(props)
        this.account = props.account
        // web3
        this.web3 = new Web3(window.ethereum)
        // vaultcontract
        this.vaultAbi = vaultAbi.abi
        this.vaultAddress = contractAddress.vaultAddr
        this.vaultContract = new this.web3.eth.Contract(this.vaultAbi,this.vaultAddress)
        // BaseERC20Contract
        this.erc20Abi = erc20Abi.abi
        this.baseAddress = contractAddress.baseERC20Addr
        this.baseTokenContract = new this.web3.eth.Contract(this.erc20Abi,this.baseAddress)
        //this.baseTokenContract.defaultChain = "rinkeby"
        // LongERC20Contract
        this.longAddress = contractAddress.longERC20Addr
        this.longTokenContract = new this.web3.eth.Contract(this.erc20Abi,this.longAddress)
        
        this.vaultList = new vaultList()
        this.vaultList.getVaultList()


        this.state={
            balance: "-",
            priceOf: "",
            price:"-"
        }
    }

    getchainId= async()=>{
        const chainId = await window.ethereum.request({ method: 'eth_chainId' })
    }

    onMintChange = (e) =>{
        this.setState({
            "mintAmount":e.target.value,
        })
    }

    onMintClick = async() =>{
        this.accounts = await window.ethereum.request({ method: "eth_accounts" })
        this.val = new BigNumber(this.state.mintAmount).shiftedBy(18).toString()
        await this.baseTokenContract.methods.mint(this.accounts[0],this.val).send({from:this.accounts[0]})
    }

    onDepositChange = (e) =>{
        this.setState({
            "depositAmount":e.target.value,
        })
        
    }

    postData = async(url = "", data = {}) => {
        const response = await fetch(url, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache', 
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          });
        return response.json();
    }

    onDepositClick = async() =>{
        this.accounts = await window.ethereum.request({ method: "eth_accounts" })
        let allowed = await this.baseTokenContract.methods.allowance(this.accounts[0],this.vaultAddress).call()
        allowed = new BigNumber(allowed)
        let amount = new BigNumber(this.state.depositAmount)
        if(amount.comparedTo(allowed)>0)
            await this.baseTokenContract.methods.approve(this.vaultAddress, this.state.depositAmount).send({ from: this.accounts[0] })
        await this.vaultContract.methods.deposit(this.state.depositAmount).send({from:this.accounts[0],value:0})
        //await this.vaultContract.methods.deposit(this.web3.utils.toBN(this.state.depositAmount)).send({from:this.accounts[0],value:0})
    }

    onWithDrawChange = (e) =>{
        this.setState({
            "withdrawAmount":e.target.value,
        })
        
    }

    onWithDrawClick = async() =>{
        this.accounts = await window.ethereum.request({ method: "eth_accounts" })
        await this.vaultContract.methods.withdraw(this.web3.utils.toBN(this.state.withdrawAmount)).send({from:this.accounts[0]})
    }

    onBalClick = async() =>{
        this.accounts = await window.ethereum.request({ method: "eth_accounts" })
        let allowed = await this.baseTokenContract.methods.allowance(this.accounts[0],this.vaultAddress).call()
        //let balance = await this.baseTokenContract.methods.balanceOf(this.accounts[0]).call()
        //let decimals = await this.baseTokenContract.methods.decimals().call()
        //console.log(decimals)
        //balance = (parseInt(balance)/(10**parseInt(decimals))).toFixed(parseInt(decimals))
            
        //TODO: for future need of token address
        //const balance = await this.web3.eth.getBalance(this.accounts[0])
        //const address = await this.web3.eth.ens.getAddress('dgd.thetoken.eth')
        this.setState({balance:allowed})
    }

    onPriceTextChange = (e) => {
        this.setState({
            "priceOf":e.target.value,
        })
    }

    onPriceClick = async(e) => {
        //var price = await getCoinGekoPrice(this.state.priceOf)
        var price = await getTokenPrice(this.state.priceOf)
        this.setState({"price":price})
    }

    onCGPriceTextChange = (e) => {
        this.setState({
            "priceOfCG":e.target.value,
        })
    }

    onCGPriceClick = async(e) => {
        //var price = await getCoinGekoPrice(this.state.priceOf)
        var price = await getCoinGekoPrice(this.state.priceOfCG)
        this.setState({"CGprice":price})
    }

    getErc20Balance = async()=>{
        this.accounts = await window.ethereum.request({ method: "eth_accounts" })
    }

    componentDidMount = async () => {
        this.postData("https://api.thegraph.com/subgraphs/name/sushiswap/matic-minichef", {
            "operationName": "miniChefPoolsQuery",
            "query": "query miniChefPoolsQuery($first: Int! = 1000, $skip: Int! = 0, $orderBy: String! = \"id\", $orderDirection: String! = \"desc\") {\n  pools(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {\n    id\n    pair\n    rewarder {\n      id\n      rewardToken\n      rewardPerSecond\n    }\n    allocPoint\n    lastRewardTime\n    accSushiPerShare\n    slpBalance\n    userCount\n    miniChef {\n      id\n      sushiPerSecond\n      totalAllocPoint\n    }\n  }\n}\n"
        })
            .then((res) => {
                console.log(res)
        })
    }

    render() {
        return (
            <>
                <div className="page-container">
                    <h3>Mint Based</h3>
                    <input type="text" onChange={this.onMintChange} placeholder="amount" />
                    <input type="button" value="mint" onClick={this.onMintClick} />
                    {
                        /*
                        <input type="text" onChange={this.onDepositChange}/>
                        <input type="button" value="deposit" onClick={this.onDepositClick} />
                        <input type="text" onChange={this.onWithDrawChange}/>
                        <input type="button" value="withdraw" onClick={this.onWithDrawClick}/>
                        */
                        
                        
                    }
                    <p>{this.state.balance}</p>
                    <input type="button" value="show allowance of base token" onClick={this.onBalClick} />
                    
                    <hr />
                    <div className="row">
                        <h3>Token Price</h3>
                        <input type="text" onChange={this.onPriceTextChange} value={this.state.priceOf} placeholder="address"/>
                        <input type="button" value="show price" onClick={this.onPriceClick} />
                        <p>{"price = "+this.state.price}</p>
                    </div>
                    <hr />
                    {
                        /*
                        <div className="row">
                        <h3>Coin Geko price</h3>
                        <input type="text" onChange={this.onCGPriceTextChange} value={this.state.priceOfCG} />
                        <input type="button" value="show price" onClick={this.onCGPriceClick} />
                        <p>{"price = "+this.state.CGprice}</p>
                        </div>
                        */
                    }
                    <ChangeChainCard web3={this.web3}/>
                    
                </div>
            </>
        )
    }
}